<template>
    <div class="card">
        <div class="bg-blue-light px-1 rounded pb-2">
            <TitleButton
                class="mt-1"
                btnTitle="Go Back"
                title="Create Receipt"
                @onClickAddNewButton="goToList"
            />

            <div class="row mt-1">
                <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                    <label for="colFormLabel" class="col-form-label">Date</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="date"
                        placeholder=""
                        v-model="receipt.date"
                    >
                </div>
                <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                    <label for="colFormLabel" class="col-form-label">Voucher</label>
                    <input type="hidden" class="form-control invoice-edit-input date-picker flatpickr-input" readonly="readonly">
                    <div class="input-group input-group-merge invoice-edit-input-group">
                        <div class="input-group-text">
                            <span>{{ receipt.voucher_prefix }}-</span>
                        </div>
                        <input type="text" class="form-control invoice-edit-input" placeholder="" v-model="receipt.voucher_serial">
                    </div>
                </div>
                <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                    <label for="colFormLabel" class="col-form-label">Received in</label>
                     <v-select
                        placeholder="Select Cash & Bank Account"
                        class="w-100"
                        v-model="debit.account_head_id"
                        :options="cashAndBack"
                        label="name"
                        :reduce="name => name.id"
                    />
                </div>
                <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                    <label for="colFormLabel" class="col-form-label">Party</label>
                     <v-select
                        placeholder="Select Party"
                        class="w-100"
                        :options="contactProfiles"
                        label="name"
                        :reduce="name => name.id"
                        v-model="receipt.contact_profile_id"
                    />
                </div>
                <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                    <label for="colFormLabel" class="col-form-label">Business</label>
                    <v-select
                        placeholder="Select Business"
                        class="w-100"
                        :options="businesses"
                        label="name"
                        :reduce="name => name.id"
                        v-model="receipt.business_id"
                    />
                </div>
            </div>
        </div>
        <div class="card p-2">
            <AddFormElement
                class="mb-2"
                :accountHeads="accountHeads"
                v-for="(item, index) in receipt.general_ledgers"
                :key="index"
                :index="index"
                :item="item"
                @onClose="onClose"
            />

            <div class="row me-1 mt-3">
                <div class="col-12 col-sm-4 col-lg-8 mb-1 mb-sm-0">
                    <AddButton title="Add line" @onClickAdd="onClickAdd"/>
                </div>
                <div class="col-6 col-sm-4 col-lg-4"><input readonly type="number" class="form-control text-end" :value="totalCredit"></div>
                <!-- <div class="col-6 col-sm-4 col-lg-2"><input readonly type="number" class="form-control" :value="totalCredit"></div> -->
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <div class="mb-2">
                        <label for="note" class="form-label fw-bold">Memo</label>
                        <textarea v-model="receipt.note" placeholder="Memo" class="form-control" rows="2" id="note"></textarea>
                    </div>
                </div>
            </div>

            <div class="mt-2 text-center">
                <hr class="mb-1">
                <button
                    :disabled="receiptLoading"
                    @click="saveReceipt(true)"
                    class="btn btn-primary me-1"
                >
                    <div v-if="receiptLoading" class="spinner-border spinner-border-sm text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    Save
                </button>
                <button
                    :disabled="saveNewLoader"
                    @click="saveReceipt(false)"
                    class="btn btn-primary me-1"
                >
                    <div v-if="saveNewLoader" class="spinner-border spinner-border-sm text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    Save & New
                </button>
                <button
                    :disabled="saveNewLoader"
                    @click="goToList" 
                    class="btn btn-outline-secondary cancel-btn"
                >
                    Cancel
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import handleReceipt from '@/services/modules/receipt'
import { inject } from 'vue'
import handleContact from '@/services/modules/contact'
import handleCBusinesses from '@/services/modules/businesses'
import {generateVoucherNumber, generateQuery} from "@/services/utils/voucherNumberGenerator";

import TitleButton from '@/components/atom/TitleButton'
import AddFormElement from '@/components/molecule/company/receipt/AddFormElement'
import AddButton from '@/components/atom/AddButton'

export default {
    name: 'ReceiptCreate',
    components: {
        TitleButton,
        AddFormElement,
        AddButton
    },
    data: () => ({
        cashAndBack: [],
        accountHeads: [],
        contactProfiles : [],
        businesses : [],
        saveNewLoader: false,
        debit: {
            account_head_id: null
        },
        receipt: {
            company_id:'',
            contact_profile_id: '',
            business_id: '',
            date: '',
            note: '',
            mop_references: 'mop',
            voucher_prefix : '',
            voucher_serial : '',
            voucher_no : '',
            // voucher_type: 'cash_voucher',
            general_ledgers: [
                {
                    account_head_id: null,
                    debit: null,
                    credit: null,
                    note: '',
                    tax_rate: null,
                    taxable_amount: null
                },
                {
                    account_head_id: null,
                    debit: null,
                    credit: null,
                    note: '',
                    tax_rate: null,
                    taxable_amount: null
                }
            ]
        }
    }),
    computed: {
        isLoading () {
            return this.receiptLoading || this.saveNewLoader
        },
        start () {
            return this.$route.query.start
        },
        end () {
            return this.$route.query.end
        },
        general () {
            return this.receipt.general_ledgers
        },
        // totalDebit () {
        //     let total = 0;
        //     this.general.map(i => {
        //         if(i.debit) total += i.debit
        //     })
        //     return total
        // },
        totalCredit () {
            let total = 0;
            this.general.map(i => {
                if(i.credit) total += i.credit
            })
            return total
        },
        // isDebitCreditEqual () {
        //     return this.totalCredit === this.totalDebit
        // }
    },
    
    watch: {
        'receipt.voucher_prefix':function () {
            this.concatVoucher()
        },
        
        'receipt.voucher_serial':function () {
            this.concatVoucher()
        }
    },
    
    methods: {
        concatVoucher(){
            this.receipt.voucher_no = this.receipt.voucher_prefix + '-' + this.receipt.voucher_serial
        },
        
        async getCashAndBank() {
           // return;
            try {
                this.receiptLoading = true
                let res = await this.fetchCashAndBank(this.$route.params.companyId)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.cashAndBack = res.data
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.receiptLoading = false
            }
        },
        async getAccountHeads() {
           // return;
            try {
                this.receiptLoading = true
                let res = await this.fetchAccountHeads(this.$route.params.companyId)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.accountHeads = res.data
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.receiptLoading = false
            }
        },
        async getContactProfiles() {
          try {
            let companyId = this.$route.params.companyId
            let contactId = '';
            let q = '';
            let query = '?company_id=' + companyId + '&q=' + q + '&contact_id' + contactId;
            let res = await this.fetchContactProfiles(query)

            if(!res.status) {
            }
            if(res.status) {
              this.contactProfiles = res.data;
            }

          } catch (err) {
            if(!err.response) {
              this.showError('Something is wrong. Check your connectivity!!')
            }
            if(err.response) {
              this.showError(err.response.message)
            }
          } finally {
            this.loading = false
          }
        },
        async getBusinesses(){
        try {
          let companyId = this.$route.params.companyId
          let res = await this.fetchBusinessList('?company_id=' + companyId)

          if(!res.status) {
          }
          if(res.status) {
            this.businesses = res.data;
          }

        } catch (err) {
          if(!err.response) {
            this.showError('Something is wrong. Check your connectivity!!')
          }
          if(err.response) {
            this.showError(err.response.message)
          }
        } finally {
          this.loading = false
        }

      },

        goToList() {
            this.$router.push({name: 'receipt-voucher-list', 
                params: {
                    companyId: this.$route.params.companyId,
                    moduleId: this.$route.params.moduleId,
                    menuId: this.$route.params.menuId,
                    pageId: this.$route.params.pageId
                },
                query: {
                    start: this.start,
                    end: this.end
                }
            
            })
        },
        onClose(index) {
            this.receipt.general_ledgers.splice(index, 1)
        },
        onClickAdd() {
            this.receipt.general_ledgers.push({
                account_head_id: null,
                debit: null,
                credit: '',
                note: '',
                tax_rate: null,
                taxable_amount: null
            })
        },
        resetForm() {
            this.receipt = {
                company_id:'',
                date: '',
                note: '',
                mop_references: 'mop',
                voucher_type: 'cash_voucher',
                general_ledgers: [
                    {
                        account_head_id: null,
                        debit: null,
                        credit: null,
                        note: '',
                        tax_rate: null,
                        taxable_amount: null
                    },
                    {
                        account_head_id: null,
                        debit: null,
                        credit: null,
                        note: '',
                        tax_rate: null,
                        taxable_amount: null
                    }
                ]
            }
        },
        getFormData () {
            let formData = new FormData();
            if(!this.receipt.contact_profile_id) this.receipt.contact_profile_id = ''
            if(!this.receipt.business_id) this.receipt.business_id = ''
            Object.keys(this.receipt).forEach(i => {
                if(i !== 'general_ledgers') {
                    formData.append(i, this.receipt[i])
                }
            })
            let generalLedgerData = this.general.slice()
            generalLedgerData.push({
                account_head_id: this.debit.account_head_id,
                debit: this.totalCredit,
                credit: null,
                note: '',
                tax_rate: null,
                taxable_amount: null
            })
            formData.append('general_ledgers', JSON.stringify(generalLedgerData))
            return formData;
        },
        async saveReceipt (redirect = false) {
            this.receipt.company_id = this.$route.params.companyId;
            let data = this.getFormData();
            if(redirect) {
                this.receiptLoading = true
            } else {
                this.saveNewLoader = true
            }
            try {
                let res = await this.createReceipt(data)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.showSuccess(res.message)
                    this.resetForm()
                    if(redirect) this.goToList()
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.data.message)
                }
            } finally {
                this.receiptLoading = false
                this.saveNewLoader = false
            }
        }
    },
    setup() {
        const showError =  inject('showError');
        const showSuccess =  inject('showSuccess');

        const {
            fetchAccountHeads,
            fetchCashAndBank,
            createReceipt,
            receiptLoading
        } = handleReceipt()

      const {
        fetchContactProfiles,
      } = handleContact()

      const {
        fetchBusinessList,
      } = handleCBusinesses()

        return {
            fetchAccountHeads,
            fetchCashAndBank,
            fetchContactProfiles,
            fetchBusinessList,
            createReceipt,
            receiptLoading,
            showError,
            showSuccess
        }
    },

    async mounted() {
        await this.getCashAndBank()
        await this.getAccountHeads()
        await this.getBusinesses()
        await this.getContactProfiles()
        this.receipt.date = new Date().toISOString().split('T')[0]
    
        let voucher = await generateVoucherNumber(generateQuery(this.$route.params.companyId, 'receipt_voucher', 'general_receipt'));
        this.receipt.voucher_prefix = voucher.prefix;
        this.receipt.voucher_serial = voucher.serial;
    }
}
</script>

<style scoped>
    .cancel-btn{
        background-color: #F0F2F5; color: #7D7D7D;
    }
    .cancel-btn:hover{
        background-color: #e9edf5;
    }
    hr{
        margin: 0 -3%;
        width: 106%;
    }
</style>
